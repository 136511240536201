.modal-drawer {
  display: grid;
  grid-template-columns: 13.8rem auto;
  grid-template-rows: auto 1fr auto;
  gap: 1rem;
  position: absolute;
  width: calc(100% - 10rem);
  top: 50%;
  left: 50%;
  height: calc(100% - 4rem);
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;

  .rule-drawer-container {
    gap: 0.5rem;
    height: 100%;
    padding-right: 0.5rem;
    overflow: auto;

    .basic-details {
      display: grid;
      grid-template-columns: calc(30% - 0.25rem) calc(70% - 0.25rem);
      align-items: center;
      gap: 0.5rem;
      width: 100%;
    }

    .rule-drawer-section-box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
    }
  }

  .rule-drawer-top {
    display: flex;
    justify-content: space-between;
    grid-column: 2;
    grid-row: 1;

    .title-top {
      display: flex;
      gap: 0.5rem;
      align-items: end;
    }

    .title-top-divider {
      width: auto;
    }
  }

  .rule-drawer-tablist {
    &.MuiTabs-root .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .MuiChip-root {
      justify-content: flex-start;
      width: 100%;
    }
  }

  .MuiButtonBase-root.MuiTab-root {
    width: 100%;
    align-items: flex-start;
  }

  .rule-drawer-sections {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }

  .rule-drawer-sidebar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    grid-column: 1;
    grid-row: 1 / 4;
  }

  .drawer-buttons {
    display: flex;
    justify-content: space-between;
    grid-column: 2;
    grid-row: 3;
  }

  .drawer-hits {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: auto;

    p {
      font-size: 0.9rem;
    }
  }
}

.drawer-block {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.actions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .MuiRadio-root {
    padding: 0;
  }

  .actions-flex {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
  }
}

.actions-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .MuiRadio-root {
    padding: 0;
  }

  .actions-flex {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
  }
}

.radio-block {
  display: flex;
  flex-direction: column;
}

.error-drawer {
  color: var(--error-dark);
  max-width: 30rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem;
  border-radius: 1rem;
  margin: 0.75rem auto;
  border: 1px solid var(--error-light);
  font-size: 0.9rem;
}

.title-parameter {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.ras-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.rule-parameters-margin {
  margin-left: 1rem;
}
