.preview-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .box-division {
    margin-bottom: 0.5rem;
  }

  .preview-title {
    font-size: larger;
    margin-bottom: 0.85rem;
  }
}

.preview-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: calc(75vh - 15rem);
  overflow: auto;
}

.preview-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  max-height: 32rem;
  overflow: auto;

  .preview-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }
}

.preview-sub-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.preview-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.render-data-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .render-data-items {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .render-data-items-row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}

.preview-requests {
  display: flex;
  gap: 1rem;
  width: 100%;
}
